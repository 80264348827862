<template>
    <div class="withdraw-applied">
        <img src="./img/withdraw-suc-icon.png" >
        <div class="desc">
            提现申请成功<br/>
            将会在 3 个小时内审核您的信息<br/>
            周末提款周一到账哦
        </div>
        <button class="btn" @click="checkProgress">查看提现进度</button>
</div>
</template>

<script>
import { shareCtrl } from '@/common/js/mmcModule';
import utils from "@/common/js/utils";
import { authChecker } from '@/common/js/mmcModule';
const params = utils.getRequestParams();

export default {
    name: 'WithdrawApplied',
    created() {
        authChecker.check({ notneedphone: 1 },() => {
            shareCtrl.init();
        })
    },
    methods: {
        checkProgress() {
            const { withdrawals_id, projuuid } = params;
            this.$router.push({
                path: '/fund/withdraw/timeline',
                query: { withdrawals_id, projuuid }
            })
        }
    }
}
</script>

<style lang='less' scoped>
.withdraw-applied {
    padding: 0 20px;

    img {
        width: 200px;
        margin: 50px auto 10px;
        display: block;
    }

    .desc {
        font-size: 17px;
        color: #333333;
        text-align: center;
        margin-bottom: 70px;
    }

    .btn {
        width: 90%;
        background-image: linear-gradient(90deg, #40B9FF 0%, #009BFF 100%);
        box-shadow: 0 2px 15px 0 rgba(0,155,255,0.40);
        border-radius: 4px;
        opacity: 0.87;
        font-size: 16px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        width: 100%;
        height: 50px;
        line-height: 50x;
        border: none;
    }
}
</style>
